<template>
  <header :class="headerClass">
    <div id="pre" :style="{ width: widthPre + '%' }"></div>
    <nav>
      <div class="logo">
        <div class="logoBox">
          <router-link :to="'/'" class="hand-style">
            <el-image
              class="img"
              style="
                width: 50px;
                border-radius: 50%;
                height: 50px;
                border: 1px solid var(--border-line);
              "
              :src="
                $store.state.webSiteInfo.logo ||
                require('@/assets/img/logo.png')
              "
            >
            </el-image>
            <span class="name">提示词365</span>
          </router-link>
        </div>
        <!-- 手机端导航栏 -->
        <span v-if="isMobile" style="position: absolute; left: 10px">
          <!-- <a @click="openSearch"><i class="iconfont iconsousuo" /></a> -->
          <a @click="openDrawer" style="font-size: 20px">
            <i class="iconfont icon-menu_normal" style="font-size: 1.5rem" />
          </a>
        </span>
        <!-- 手机端搜索栏 -->
        <span v-if="isMobile" style="position: absolute; right: 10px">
          <!-- <a @click="openSearch"><i class="iconfont iconsousuo" /></a> -->
          <a @click="handleOpenSearchDialog" style="font-size: 20px">
            <i class="iconfont icon-search" style="font-size: 1.5rem" />
          </a>
        </span>
      </div>

      <ul class="starlist">
        <li :class="path == '/' ? 'active' : ''">
          <span>
            <router-link to="/" class="hand-style">
              <svg-icon icon-class="home"></svg-icon> 首页
            </router-link>
          </span>
        </li>

        <li :class="path == '/category' ? 'active' : ''">
          <span>
            <router-link to="/category" class="hand-style">
              <svg-icon icon-class="category"></svg-icon> 文章分类
            </router-link>
          </span>
        </li>

        <li :class="path == '/tags' ? 'active' : ''">
          <span>
            <router-link to="/tags" class="hand-style">
              <svg-icon icon-class="tag"></svg-icon> 文章标签
            </router-link>
          </span>
        </li>

        <!-- <li
          :class="
            path == '/archive' || path == '/categorys' || path == '/tag'
              ? 'active'
              : ''
          "
        >
          <el-dropdown trigger="hover">
            <span class="el-dropdown-link hand-style">
              <svg-icon icon-class="archive"></svg-icon> 文章归档<i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link
                style="text-decoration: none; color: #71777c"
                :to="'/archive'"
              >
                <el-dropdown-item>
                  <i class="el-icon-date"></i>文章归档
                </el-dropdown-item>
              </router-link>
              <router-link
                style="text-decoration: none; color: #71777c"
                :to="'/category'"
              >
                <el-dropdown-item>
                  <i class="el-icon-menu"></i>文章分类
                </el-dropdown-item>
              </router-link>

              <router-link
                style="text-decoration: none; color: #71777c"
                :to="'/tags'"
              >
                <el-dropdown-item>
                  <i class="el-icon-collection-tag"></i>文章标签
                </el-dropdown-item>
              </router-link>
            </el-dropdown-menu>
          </el-dropdown>
        </li> -->

        <!-- <li :class="path == '/say' ? 'active' : ''">
          <span>
            <router-link :to="'/say'" class="hand-style">
              <svg-icon icon-class="say"></svg-icon> 说说
            </router-link>
          </span>
        </li> -->

        <li :class="path == '/navigation' ? 'active' : ''">
          <span>
            <router-link to="/navigation" class="hand-style">
              <svg-icon icon-class="navication"></svg-icon> 网址导航
            </router-link>
          </span>
        </li>

        <!-- <li :class="path == '/hot' ? 'active' : ''">
          <span>
            <router-link to="/hot" class="hand-style">
              <svg-icon icon-class="hot2"></svg-icon> 热搜
            </router-link>
          </span>
        </li> -->
        <!-- <li :class="path == '/message' ? 'active' : ''">
          <span>
            <router-link :to="'/message'" class="hand-style">
              <svg-icon icon-class="message"></svg-icon> 留言板
            </router-link>
          </span>
        </li> -->
        <!-- <li :class="path == '/links' ? 'active' : ''">
          <span>
            <router-link to="/links" class="hand-style">
              <svg-icon icon-class="friendLink"></svg-icon> 友情链接
            </router-link>
          </span>
        </li> -->

        <li :class="path == '/about' ? 'active' : ''">
          <span>
            <router-link to="/about" class="hand-style">
              <svg-icon icon-class="about"></svg-icon> 关于本站
            </router-link>
          </span>
        </li>

        <!-- <li :class="path == '/about' ? 'active' : ''">
          <el-dropdown trigger="hover">
            <span class="el-dropdown-link hand-style">
              <svg-icon icon-class="about"></svg-icon> 关于本站<i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link
                style="text-decoration: none; color: #71777c"
                :to="'/about'"
              >
                <el-dropdown-item>
                  <i class="el-icon-monitor"></i>关于本站
                </el-dropdown-item>
              </router-link>

              <a
                style="text-decoration: none; color: #71777c"
                href="https://tishici"
                target="_blank"
              >
                <el-dropdown-item>
                  <i class="iconfont icon-zitidaima"></i>网站源码
                </el-dropdown-item>
              </a>

              <a
                style="text-decoration: none; color: #71777c"
                :href="adminUrl"
                target="_blank"
              >
                <el-dropdown-item>
                  <i class="el-icon-setting"></i>后台管理
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </li> -->
      </ul>
      <div class="right">
        <!-- 搜索框 -->
        <div class="searchBox" v-show="!showSearch">
          <div class="search_ico">
            <div class="hand-style" @click="handleOpenSearchDialog">
              <i class="iconfont icon-search"></i>
              搜索提示词...
              <span> Ctrl+K </span>
            </div>
          </div>
        </div>
        <router-link to="/newArticle" class="articleBtn" v-if="userInfo">
          <el-button type="primary" size="medium"
            >新建文章</el-button
          ></router-link
        >

        <div class="noticeBtn" v-if="userInfo">
          <el-dropdown trigger="hover">
            <div class="el-dropdown-link hand-style">
              <svg-icon icon-class="notice"></svg-icon>
              <span v-if="topBageShow()" class="notice-bage topBage"></span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <span
                v-for="(item, index) in noticeList"
                :key="index"
                @click="handleClickNotice(index)"
              >
                <el-dropdown-item>
                  {{ item }}
                  <span v-if="validata(index)" class="notice-bage"></span>
                </el-dropdown-item>
              </span>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="userInfo" v-if="showUser">
          <el-dropdown trigger="hover">
            <div
              class="el-dropdown-link hand-style"
              style="height: 42px; width: 42px"
            >
              <img
                v-if="!userInfo"
                src="@/assets/img/touristAvatar.png"
                alt=""
              />
              <img v-else :src="userInfo.avatar" alt="" />
            </div>
            <el-dropdown-menu slot="dropdown" v-if="userInfo">
              <router-link
                style="text-decoration: none; color: #71777c"
                :to="'/user'"
              >
                <el-dropdown-item> 个人中心 </el-dropdown-item>
              </router-link>
              <a style="text-decoration: none; color: #71777c" @click="logout">
                <el-dropdown-item>退出登录</el-dropdown-item>
              </a>
            </el-dropdown-menu>

            <el-dropdown-menu slot="dropdown" v-else>
              <div class="loginTip" style="padding: 10px; font-size: 0.9rem">
                <p>登录网站，获取以下权益</p>
                <div style="margin-top: 10px">
                  <span> <i class="el-icon-cloudy"></i> 参与互动评论 </span>

                  <span style="margin-left: 10px">
                    <i class="el-icon-cloudy"></i> 发表优质文章
                  </span>
                </div>
                <el-button
                  @click="handleLogin()"
                  style="margin: 0 auto; display: block; margin-top: 10px"
                  type="success"
                  size="small"
                  >立即登录</el-button
                >
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { logout } from "@/api";
import { removeToken, getToken } from "@/utils/cookieUtil";
export default {
  name: "Header",

  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      widthPre: "",
      keywords: null,
      user: this.$store.state.userInfo,
      style: null,
      path: null,
      isMobile: false,
      showSearch: false,
      showUser: true,
      windowWidth: 0,
      headerClass: "header",
      adminUrl: process.env.VUE_APP_ADMIN_API,
      noticeList: ["系统通知", "评论", "私信"],
    };
  },
  mounted() {
    this.isMobile = window.innerWidth < 1119;
  },
  created() {
    window.addEventListener(
      "resize",
      () => {
        this.windowWidth = window.innerWidth; // 宽
      },
      true
    );
    window.addEventListener("scroll", this.scrollFn, false);
  },

  watch: {
    // 监听页面宽度
    windowWidth(val) {
      this.isMobile = val < 1119;
      this.showSearch = val < 1500;
      this.showUser = val > 1350;
    },
    $route(newVal, old) {
      this.path = newVal.fullPath;
    },
  },

  methods: {
    handleOpenSearchDialog() {
      this.$store.state.searchDialogVisible = true;
    },
    handleClickNotice(index) {
      if (!getToken()) {
        this.$store.commit("setLoginFlag", true);
        return;
      }
      if (index == this.noticeList.length - 1) {
        this.$router.push({ path: "/im" });
        return;
      }
      this.$router.push({ path: "/notice", query: { type: index } });
    },
    topBageShow() {
      return (
        this.$store.state.systemNotcie.system > 0 ||
        this.$store.state.systemNotcie.comment > 0 ||
        this.$store.state.systemNotcie.private > 0
      );
    },
    validata(index) {
      switch (index) {
        case 0:
          return this.$store.state.systemNotcie.system > 0;
        case 1:
          return this.$store.state.systemNotcie.comment > 0;
        case 2:
          return this.$store.state.systemNotcie.private > 0;
        default:
          return false;
      }
    },

    scrollFn() {
      // 页面滚动距顶部距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let scroll = scrollTop - this.i;
      this.i = scrollTop;

      // 鼠标向上滚动
      if (scroll < 0) {
        this.headerClass = "header slideDown";
      } else {
        this.headerClass = "header slideUp";
      }
      let page = document.body.scrollHeight;
      let client = document.documentElement.clientHeight;
      let comend = page - client;
      let scrTop = document.documentElement.scrollTop;
      this.widthPre = Math.round((scrTop / comend) * 10000) / 100;
    },
    handleLogin() {
      this.$store.commit("setLoginFlag", true); // 存储到vuex
    },
    logout() {
      //如果在个人中心则跳回上一页
      if (this.path === "/user") {
        this.$router.go(-1);
      }
      logout()
        .then((res) => {
          removeToken();
          this.$store.commit("setUserInfo", null);
          location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDrawer() {
      this.$store.commit("setDrawer", true);
    },
  },
};
</script>
<style scoped lang="scss">
.notice-bage {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

.topBage {
  vertical-align: 15px;
}

#pre {
  position: fixed;
  top: 0;
  height: 2px;
  background-image: -webkit-linear-gradient(0deg, #3ca5f6 0, #a86af9 100%);
}
.right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header {
  background-image: radial-gradient(transparent 1px, var(--bg-color) 1px);
  background-size: 4px 4px;
  backdrop-filter: saturate(50%) blur(4px);
  -webkit-backdrop-filter: saturate(50%) blur(4px);
}
@media screen and (max-width: 1118px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    line-height: 60px;
    z-index: 9999;
    background-color: var(--header-back-color);

    a {
      text-decoration: none;
    }

    nav {
      width: 100%;
      margin: auto;
      overflow: hidden;
      max-width: 1500px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        font-size: 22px;
        font-weight: 700;
        width: 100%;
        position: relative;
        display: flex;
        color: var(--text-color);

        .logoBox {
          height: 60px;
          display: flex;
          align-items: center;
          margin-left: 20px;
          position: relative;
          margin: 0 auto;
        }

        .img {
          width: 80px;
          height: 40px;
          vertical-align: middle;
          margin-top: -6px;
        }

        .name {
          margin-left: 10px !important;
          color: var(--theme-color) !important;
          font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
        }
      }

      .starlist {
        display: none;
      }

      .searchBox,
      .articleBtn,
      .starlist,
      .noticeBtn,
      .userInfo {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1119px) {
  /*针对宽度小于等于767px的屏幕设备，定义如下样式*/
  .slideDown {
    transition: transform 0.5s ease-out;
    transform: translateY(0);
  }

  .slideUp {
    transition: transform 0.5s ease-out;
    transform: translateY(-100px);
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    line-height: 60px;
    z-index: 9999;
    background-color: var(--header-back-color);
    backdrop-filter: blur(25px);
    box-shadow: 0 1px 40px -8px rgba(0, 0, 0, 0.5);

    a {
      text-decoration: none;
    }

    nav {
      width: 100%;
      margin: auto;
      overflow: hidden;
      max-width: 1500px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        float: left;
        font-size: 22px;
        font-weight: 700;

        .img {
          vertical-align: middle;
          margin-top: -6px;
        }

        .name {
          margin-left: 10px !important;
          color: var(--theme-color) !important;
        }
      }

      .starlist {
        display: block;
        float: left;
        // margin-left: 30px;
        overflow: hidden;
        max-width: 1500px;

        li {
          float: left;
          display: block;
          font-size: 14px;
          padding: 0 15px;
          position: relative;

          // &:hover::before {
          //     content: "";
          //     position: absolute;
          //     top: 0;
          //     left: 0;
          //     right: 0;
          //     bottom: 0;
          //     width: 60%;
          //     height: 4px;
          //     border-bottom-left-radius: 15px;
          //     border-bottom-right-radius: 15px;
          //     background-color: var(--theme-color);
          //     margin: 0 auto;
          //     text-align: center;
          //     animation: fade-in 0.3s linear 1;
          // }

          @keyframes fade-in {
            0% {
              transform: scale(0);
            }

            100% {
              transform: scale(1);
            }
          }

          svg {
            width: 18px;
            height: 18px;
            vertical-align: -3px;
          }

          i {
            font-weight: 700;
          }

          a {
            color: #71777c;

            &:hover {
              color: var(--theme-color);
            }
          }
        }

        .active {
          // &::before {
          //     content: "";
          //     position: absolute;
          //     top: 0;
          //     left: 0;
          //     right: 0;
          //     bottom: 0;
          //     width: 60%;
          //     height: 4px;
          //     border-bottom-left-radius: 15px;
          //     border-bottom-right-radius: 15px;
          //     background-color: var(--theme-color);
          //     margin: 0 auto;
          //     text-align: center;
          // }

          /deep/ a,
          span {
            color: var(--theme-color) !important;
            font-weight: 700;
          }
        }
      }

      .searchBox {
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;

        .search_ico {
          padding: 0;
          margin: 0;
          line-height: 60px;

          div {
            width: 200px;
            border: 1px solid var(--border-line);
            height: 35px;
            line-height: 35px;
            font-size: 15px;
            border-radius: 5px;
            color: #82848a;

            .iconfont {
              font-weight: 700;
              margin: 0 5px;
            }

            span {
              border: 1px solid var(--border-line);
              border-radius: 3px;
              padding: 2px 2px;
              margin-left: 15px;
              background-color: var(--background-color);
            }
          }
        }
      }

      .noticeBtn {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
        }

        i {
          font-size: 1.5rem;
          color: var(--article-color);
        }

        /deep/ .el-dropdown {
          width: 35px !important;
          height: 35px !important;
          .el-dropdown-link {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .userInfo {
        /deep/ .el-dropdown {
          width: 40px;
          height: 40px;
          right: 0;
          top: 10px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid var(--border-line);
          position: absolute;
        }
      }
    }
  }
}
</style>
