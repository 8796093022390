<template>
  <!-- 动画节点 -->
  <div id="loader-wrapper" v-if="isLoading">
    <div class="loader"></div>
    <div class="load_title">正在拼命加载中,请耐心等待....</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    this.$bus.$on("show", () => {
      this.isLoading = true;
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", () => {}, true);
    });
    this.$bus.$on("close", () => {
      this.isLoading = false;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "";
      document.removeEventListener("touchmove", mo, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("show");
    this.$bus.$off("close");
  },
};
</script>
<style lang="scss" scoped>
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  position: relative;
  left: 50%;
  top: 50%;
  margin: 0 0 0 -25px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }

  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(9, 9, 9, 0.6);

  .load_title {
    font-family: "Open Sans";
    color: #afafaf;
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 55%;
    margin-top: 20px;
    line-height: 30px;
  }
}
</style>
