<template>
  <div v-if="path != '/message'" class="footer_container">
    <div class="content">
      <div class="logo">
        <el-image class="img" :src="require('@/assets/img/logo.png')">
        </el-image>
        <span class="name">提示词365</span>
      </div>
      <div class="site">
        <div>
          <p>激发创意，一键生成你的专属提示词库！</p>
          <p>精准定位，个性化定制，助你轻松产出优质内容！</p>
          <p>
            激发无限创意，智能生成提示词，助力各类写作高效流畅，个性化定制助您突破创作瓶颈，打造优质内容。
          </p>
        </div>
        <div class="copyright">
          ©2024-{{ nowYear }}
          <a href="https://tishici365.com" target="_blank">提示词365</a>
          <a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style="margin-left: 10px"
            >{{ $store.state.webSiteInfo.recordNum }}</a
          >
        </div>
      </div>
      <div class="qr-code">
        <!-- <div class="code">
          <el-image
            class="img"
            :src="require('@/assets/img/wechatQr.jpg')"
          ></el-image>
          <div>扫码加入微信群</div>
        </div> -->
        <div class="code">
          <el-image
            class="img"
            :src="require('@/assets/img/wechatQr.jpg')"
          ></el-image>
          <div>扫码关注公众号</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      path: null,
      nowYear: new Date().getFullYear(),
    };
  },
  watch: {
    $route(newVal, old) {
      this.path = newVal.fullPath;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@keyframes yb {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  30% {
    transform: rotate(5deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  50%,
  100% {
    transform: rotate(0deg);
  }
}

.footer_container {
  padding: 15px 20px;
  position: relative;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--footer-background-color);
  background-position: 100% 0;
  margin-top: 50px;

  .content {
    margin: 0 auto;
    flex: 1;
    max-width: 1440px;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1118px) {
      flex-direction: column;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .img {
        height: 5.5rem;
        width: 5.5rem;
      }
      .name {
        font-size: 20px;
      }
    }
    .site {
      font-size: 14px;
      color: var(--text-color);
      margin-left: 10px;

      p {
        margin: 10px 0;
      }

      a {
        text-decoration: none;
        color: var(--text-color);

        &:hover {
          color: var(--theme-color);
        }
      }
      .copyright {
        margin-top: 24px;
        opacity: 0.7;
      }
    }

    .qr-code {
      display: flex;
      align-items: center;
      gap: 40px;
      .code {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          height: 7rem;
          width: 7rem;
        }
      }
    }
  }
}
</style>
